<template>
  <div class="QualityReportUnpublishList">
    <list :searchUrl="searchUrl" :searchParams.sync="searchParams" :headers="headers" ref="list">
      <template #searchSlot>
        <v-input label="计划名称" v-model="searchParams.planName" />
        <v-select label="计划类型" v-model="searchParams.planType" :options="planTypeOps" />
        <v-select label="计划层级" v-model="searchParams.scopeType" :options="scopeTypeOps" />
        <v-select2 label="检查对象" v-model="searchParams.communityId" v-bind="communityParams" />
        <v-datepicker label="报告生成时间" type="rangedatetimer" :startTime.sync="searchParams.reportCreateTimeS"
          :endTime.sync="searchParams.reportCreateTimeE" />
        <v-select label="报告状态" v-model="searchParams.reportProgress" :options="reportStatusOps" />
        <v-select label="整改进度" v-model="searchParams.rectificationProgress" :options="rectifyProgressOps" />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" permission="detail" @click="detail(scope.row)" />
        <v-button text="确认" type="text" permission="confirm" @click="confirm(scope.row)"
          v-show="isShowComfirm(scope.row)" />
        <v-button text="催改" type="text" permission="urgeChange" @click="showChangeDialog(scope.row)"
          v-show="isShowHurry(scope.row)" />
        <v-button text="发布" type="text" permission="publish" @click="publish(scope.row)"
          v-show="isShowPublish(scope.row)" />
        <v-button text="退回" type="text" permission="fallback" @click="showChangeBack(scope.row)"
          v-show="isShowFallBack(scope.row)" />
      </template>
    </list>
    <!-- 催改弹框 -->
    <el-dialog :title="'催改'" :visible.sync="isShowDialog" width="40%">
      <form-panel ref="form" :form="rushForm" v-bind="submitConfig" class="customer_form" :submitSuccess="submitSuccess"
        :submitError="submitError">
        <template #headerSlot>
          {{ '' }}
        </template>
        <el-form-item label="整改期限" prop="rectificationTime"
          :rules="[{ required: true, message: '请选择整改期限', trigger: 'blur' }]">
          <v-datepicker type="datetime" format="YYYY-MM-DD HH:mm:ss" v-model="rushForm.rectificationTime"
            :minDate='new Date()' />
        </el-form-item>
        <el-form-item label="项目通知人员">
          <w-select :width="300" :autoClearSearchValue="false" :initialAnimated="true"
            v-model="rushForm.rectificationUserId" :option="selectOption" :maxTagCount="4" :maxTitleChar="4"
            v-loading="rectificationLoading" />
        </el-form-item>
      </form-panel>
    </el-dialog>
    <!-- 退回弹框 -->
    <el-dialog :title="'退回'" :visible.sync="isShowBack" width="40%">
      <form-panel ref="form" :form="backForm" v-bind="submitBackConfig" class="customer_form"
        :submitSuccess="submitSuccess" :submitError="submitError">
        <template #headerSlot>
          {{ '' }}
        </template>
        <el-form-item label="整改期限" prop="backspaceTime"
          :rules="[{ required: true, message: '请选择整改期限', trigger: 'blur' }]">
          <v-datepicker type="datetime" format="YYYY-MM-DD HH:mm:ss" v-model="backForm.backspaceTime"
            :minDate='new Date()' />
        </el-form-item>
        <el-form-item label="项目通知人员">
          <w-select :width="300" :autoClearSearchValue="false" :initialAnimated="true" v-model="backForm.backspaceUserId"
            :option="selectOption" :maxTagCount="4" :maxTitleChar="4" v-loading="rectificationLoading" />
        </el-form-item>
        <el-form-item label="退回原因" prop="backspaceSpecifics"
          :rules="[{ required: true, message: '请填写退回原因', trigger: 'blur' }]">
          <v-textarea v-model="backForm.backspaceSpecifics" :width="360" :maxlength="200" />
        </el-form-item>
      </form-panel>
    </el-dialog>
  </div>
</template>

<script>
import { communityParams } from 'common/select2Params'
import {
  getListURL,
  rushToChangeURL,
  updateReportProgressURL,
  getNotifyPersonnelURL,
  qualityCheckTaskBackspace
} from './api'
import {
  scopeTypeMap,
  scopeTypeOps,
  planTypeMap,
  planTypeOps,
  reportStatusMap,
  setReportStatusOps,
  rectifyProgressMap,
  setRectifyProgressOps,
} from './map'

export default {
  name: 'QualityReportUnpublishList',
  computed: {
    userType () {
      return +this.$store.state.userInfo.userType
    },
    scopeTypeOps () {
      const userType = +this.$store.state.userInfo.userType
      let list = []
      if ([106, 100].includes(userType)) {
        list = scopeTypeOps()
      } else if ([102].includes(userType)) {
        list = scopeTypeOps().slice(1)
      } else if ([101].includes(userType)) {
        list = scopeTypeOps().slice(2)
      }
      list.unshift({
        text: '全部',
        value: undefined,
        label: '全部'
      })
      return list
    },
  },
  data () {
    return {
      searchUrl: getListURL,
      planTypeOps: planTypeOps(1),
      reportStatusOps: setReportStatusOps(),
      rectifyProgressOps: setRectifyProgressOps(1),
      communityParams,
      searchParams: {
        planName: '',
        scopeType: undefined,
        planType: undefined,
        communityId: undefined,
        rectificationProgress: undefined,
        reportCreateTimeS: '',
        reportCreateTimeE: '',
        reportProgress: 0,
        reportProgressType: 1,
      },
      headers: [
        {
          prop: 'planType',
          label: '计划类型',
          formatter (row) {
            return planTypeMap[row.planType] || ''
          }
        },
        {
          prop: 'taskName',
          label: '计划名称'
        },
        {
          prop: 'templateName',
          label: '检查表模板'
        },
        {
          prop: 'communityName',
          label: '检查对象'
        },
        {
          prop: 'planStartTime',
          label: '计划生成时间'
        },
        {
          prop: '',
          label: '计划层级',
          formatter (row) {
            return scopeTypeMap[row.scopeType] || ''
          }
        },
        {
          prop: 'responsibleUser',
          label: '计划责任人'
        },
        {
          prop: '',
          label: '检查得分/总分',
          formatter (row) {
            const { executeUserId, checkScore = 0, totalScore = 0, displayModel, checkMark = 0 } = row
            if (!executeUserId) { // 任务执行状态为未开始时，返回空
              return ''
            }
            // displayModel 1 按评分 2 按是否合格
            if (displayModel === 1) {
              return `${checkMark} / ${totalScore}`
            } else {
              return `${checkScore}项合格 / ${totalScore}项`
            }
          }
        },
        {
          prop: '',
          label: '整改进度',
          formatter (row) {

            //TODO 待加入时间  rectificationTime 整改完成时间 urgeChangeTime 催改期限
            const { rectificationProgress, rectificationTime, urgeChangeTime } = row
            let timeStr = ''
            if (rectificationProgress == 2) {
              timeStr = `(${urgeChangeTime})`
            }
            if ([3, 4].includes(rectificationProgress)) {
              timeStr = `(${rectificationTime})`
            }
            return `${rectifyProgressMap[rectificationProgress] || ''}   ${timeStr}`
          }
        },
        {
          prop: 'statusText',
          label: '报告状态',
          formatter (row) {
            return reportStatusMap[row.reportProgress] || ''
          }
        },
      ],
      isShowDialog: false,
      rushForm: {
        rectificationProgress: '',
        rectificationTime: '',
        rectificationUserId: [],
        taskId: undefined,
      },
      backForm: {
        taskId: undefined,
        backspaceSpecifics: '',
        backspaceTime: undefined,
        backspaceUserId: [],
      },
      rectificationLoading: false,
      selectOption: [],
      submitConfig: {
        submitUrl: rushToChangeURL
      },
      submitBackConfig: {
        submitUrl: qualityCheckTaskBackspace
      },
      isShowBack: false
    }
  },
  methods: {
    detail (row) {
      let { rectificationProgress } = row
      let query = {
        id: row.taskId,
      }
      query.isDetail = rectificationProgress == 2 ? undefined : true
      query.isRectify = rectificationProgress;
      this.$router.push({
        name: 'qualityReportUnpublishForm',
        query
      })
    },
    isShowComfirm (row) {
      // 确认按钮 未整改 未确认
      let { reportProgress, rectificationProgress } = row
      return +reportProgress === 1 && +rectificationProgress === 1
    },
    isShowHurry (row) {
      // 催改  未整改 已确认
      let { reportProgress, rectificationProgress } = row
      return +reportProgress === 2 && +rectificationProgress === 1
    },

    isShowPublish (row) {
      // 发布 待发布 //退回
      let { reportProgress } = row
      return +reportProgress === 3
    },
    isShowFallBack (row) {
      // ！=整改中 //退回
      let { reportProgress, rectificationProgress } = row
      return +reportProgress === 3 && +rectificationProgress !== 2
    },
    async confirm (row) {
      let isConfirm = await this.$confirm('是否确认报告？')
      if (isConfirm) {
        this.confirmRequest(row)
      }
    },
    confirmRequest (row) {
      const { taskId } = row
      this.$axios.post(updateReportProgressURL, null, {
        params: {
          taskId,
          type: 1
        }
      }).then((res) => {
        const { status } = res
        if (status == 100) {
          this.$message.success('已确认')
          this.$refs.list.searchData()
        }
      })
    },
    // 退回
    showChangeBack (row) {
      Object.keys(this.rushForm).forEach((key) => {
        if ([undefined, '', null].includes(row[key])) {
          return
        }
        this.backForm[key] = row[key]
        this.backForm.backspaceTime = ''
        this.backForm.backspaceSpecifics = ''
        // this.backForm.backspaceTime = row.rectificationTime
        // this.backForm.backspaceUserId = row.rectificationUserId || []
      })
      const { communityId } = row
      this.rectificationLoading = true
      this.isShowBack = true
      this.getSelectOption(communityId)
    },
    // 催改
    showChangeDialog (row) {
      Object.keys(this.rushForm).forEach((key) => {
        if ([undefined, '', null].includes(row[key])) {
          return
        }
        this.rushForm[key] = row[key]
        this.rushForm.backspaceTime = row.rectificationTime
        this.rushForm.backspaceUserId = row.rectificationUserId || []
      })

      const { communityId } = row
      this.rectificationLoading = true
      this.isShowDialog = true
      this.getSelectOption(communityId)
    },
    getSelectOption (communityId) {
      this.$axios.get(getNotifyPersonnelURL, {
        params: {
          curPage: 1,
          pageSize: 10000,
          row: 10000,
          roleType: 101,
          organizeId: communityId,
          status: 1
        }
      }).then((res) => {
        if (res.status === '100') {
          this.selectOption = []
          let { data: { resultList = [] } } = res
          let isHasObj = {}
          let filterList = []
          resultList.forEach((item) => {
            if (!isHasObj[item.id]) {
              isHasObj[item.id] = true
              filterList.push({
                label: item.userName,
                value: item.id
              })
            }
          })
          this.selectOption = [
            ...filterList
          ]
        }
        this.rectificationLoading = false
      }).catch(() => {
        this.selectOption = []
        this.rectificationLoading = false
      })
    },
    submitSuccess () {
      this.isShowDialog = false
      this.isShowBack = false
      this.rushForm.rectificationUserId = []
      this.backForm.backspaceUserId = []
      this.$refs.list.searchData()
    },
    submitError () {
      this.rushForm.rectificationUserId = []
      this.backForm.backspaceUserId = []
    },

    async publish (row) {
      let isConfirm = await this.$confirm('是否确认发布该报告？')
      if (isConfirm) {
        this.publishRequest(row)
      }
    },
    publishRequest (row) {
      const { taskId } = row
      this.$axios.post(updateReportProgressURL, null, {
        params: {
          taskId,
          type: 2
        }
      }).then((res) => {
        const { status } = res
        if (status == 100) {
          this.$message.success('已发布')
          this.$refs.list.searchData()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.customer_form {
  ::v-deep .content {
    height: 50vh;
  }
}
</style>

import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '是',
    value: 1
  },
  {
    text: '否',
    value: 0
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

// 计划类型
const planType = [
  {
    text: '品质督查',
    value: 1
  },
  {
    text: '项目自查',
    value: 2
  }
]

const {
  map: planTypeMap,
  setOps: planTypeOps
} = mapHelper.setMap(planType)

// 计划层级
const scopeType = [
  {
    text: '平台级',
    value: 1
  },
  {
    text: '公司级',
    value: 2
  },
  {
    text: '项目级',
    value: 3
  },
]

const {
  map: scopeTypeMap,
  setOps: scopeTypeOps
} = mapHelper.setMap(scopeType)

// 报告状态
const reportStatus = [
  {
    text: '全部',
    value: 0
  },
  {
    text: '待确认',
    value: 1
  },
  {
    text: '已确认',
    value: 2
  },
  {
    text: '待发布',
    value: 3
  }
]

const {
  map: reportStatusMap,
  setOps: setReportStatusOps
} = mapHelper.setMap(reportStatus)

// 整改进度
const rectifyProgress = [
  {
    text: '未整改',
    value: 1
  },
  {
    text: '整改中',
    value: 2
  },
  {
    text: '整改完成',
    value: 3
  },
  {
    text: '超时整改完成',
    value: 4
  },
]

const {
  map: rectifyProgressMap,
  setOps: setRectifyProgressOps
} = mapHelper.setMap(rectifyProgress)

const checkScore = [
  {
    text: '合格',
    value: '1'
  },
  {
    text: '不合格',
    value: '0'
  }
]
const {
  map: checkScoreMap,
  setOps: checkScoreOps
} = mapHelper.setMap(checkScore, 'label')

export {
  statusMap,
  setStatusOps,
  scopeTypeMap,
  scopeTypeOps,
  planTypeMap,
  planTypeOps,
  reportStatusMap,
  setReportStatusOps,
  rectifyProgressMap,
  setRectifyProgressOps,
  checkScoreMap,
  checkScoreOps
}

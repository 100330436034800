// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckTaskReportList`
// 编辑
const editURL = `${API_CONFIG.butlerBaseURL}qualityManagement/UpdateScQualityCheckPlanTaskDetail`   // type ->  1：保存  2：完成
// 查询详情
const queryURL = `${API_CONFIG.butlerBaseURL}qualityManagement/getQualityCheckTaskReportDetail`
// 确认
const updateReportProgressURL = `${API_CONFIG.butlerBaseURL}qualityManagement/updateReportProgress`
// 催改
const rushToChangeURL = `${API_CONFIG.butlerBaseURL}qualityManagement/rushToChange`
// 退回
const qualityCheckTaskBackspace = `${API_CONFIG.butlerBaseURL}qualityManagement/qualityCheckTaskBackspace`

// 根据项目获取项目通知人员
const getNotifyPersonnelURL = `${API_CONFIG.baseURL}serverUserAction!viewList.action`

// // 获取账号管理列表数据-项目
// const getUserListURL = `${API_CONFIG.baseURL}serverUserAction!viewList.action`


// http://172.16.11.194:8080/old/serverUserAction!viewList.action?curPage=1&pageSize=10000&row=10000&roleType=101&organizeId=e688e33b-c089-11ec-8e30-6c2b5986eb15



export {
  getListURL,
  editURL,
  queryURL,
  rushToChangeURL,
  updateReportProgressURL,
  getNotifyPersonnelURL,
  qualityCheckTaskBackspace
}
